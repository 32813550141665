// src/vuetify.ts
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Define the custom themes
const customDarkTheme = {
  dark: true,
  colors: {
    background: '#0A0C21',
    bgPrimary: 'rgba(255, 255, 255, 0.07)',
    surface: '#1b1d31',
    sheet: '#1b1d31',
    primary: '#00FFA3',
    secondary: '#03dac6',
    error: '#ff5722',
    navbar: '#343446',
    cards: '#1b1d31',
    drawer: '#0A0C21',
    customButtonColor: '#00ffa3',
    customTextColor: '#343446',
    offer: '#BDFF00',
    project: '#4CA9FF',
    projectCard: '#27293E0D',
    projectCardShadow: '#00FFA326',
    projectText: '#00FFA3',
    event: '#00F244',
    typeEvent: '#75f89a',
    typeOffer: '#dbff75',
    typeProject: '#9ed1ff',
    announcementDetailsEvent: '#36384c',
    dropdown: '#0A0C21',
    dropdownItem: '#00FFA3',
    'surface-variant': '#0A0C21',
    'on-surface-variant': '#ffffff',
    cardBackground: 'rgba(255, 255, 255, 0.07)', // semi-transparent white
    cardTextColor: '#ffffff', // white text color
    cardBorder: '#00ffa326', // Dark theme card border
    cardShadow: '#00ffa326', // Dark theme card shadow
    marketplaceCard: '#1b1c2b',
    requestBtn: '#B3B3FF',
    offerBtn: '#05C3ED',
    marketplaceDetailBtn: '#2c3fff',
    networkButton: '#b61efa',
    networkPrimary: '#00FFA3',
    newRealEstateComponent: '#1b1d31',
    realEstateCardType: '#00FFA3',
    pendingBtn:"#FFE081",
    realEstatePendingType:"#FFE9A8",
    realEstateRequestType:"#CDCDFF",
    networkingRequestBtn:"#8383EB4D",
    networkingPendingBtn:"#FFE0814D",
    networkingCircleTag:"#E09AFF",
    customColor:"#00FFA3"
  }
}

const customLightTheme = {
  dark: false,
  colors: {
    background: '#ffffff',
    bgPrimary: 'rgba(255, 255, 255)',
    surface: '#27293e',
    sheet: '#ffffff',
    primary: '#27293E',
    secondary: '#00ccff',
    error: '#ff5722',
    navbar: '#ffffff',
    cards: '#ffffff',
    drawer: '#ffffff',
    customButtonColor: '#27293E',
    customTextColor: '#ffffff',
    offer: '#FFE081',
    project: '#05C3ED',
    projectCard: '#F4F4F4',
    projectCardShadow: '#FAFAFA',
    projectText: '#8811FF',
    event: '#B3B3FF',
    typeEvent: '#FFFFFF75',
    typeOffer: '#FFFFFF75',
    typeProject: '#FFFFFF75',
    announcementDetailsEvent: '#e2fae9',
    dropdown: '#ffffff',
    dropdownItem: '#ffffff',
    cardBackground: '#ffffff', // solid white
    cardTextColor: '#000000', // black text color
    cardBorder: '#e0e0e0', // Light theme card border
    cardShadow: '#e0e0e0', // Light theme card shadow
    marketplaceCard: '#ffffff',
    requestBtn: '#B3B3FF',
    offerBtn: '#05C3ED',
    marketplaceDetailBtn: '#6371ff',
    networkButton: '#E09AFF',
    networkPrimary: '#6DE98A',
    newRealEstateComponent: '#fcfcfc',
    realEstateCardType: '#FFFED5',
    pendingBtn:"#FFE081",
    realEstatePendingType:"#FFE9A8",
    realEstateRequestType:"#CDCDFF",
    networkingRequestBtn:"#8383EB4D",
    networkingPendingBtn:"#F4C73F4D",
    networkingCircleTag:"#E09AFF",
    customColor:"#05ED7F",
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: customDarkTheme,
      light: customLightTheme
    }
  }
})

export default vuetify

